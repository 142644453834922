import {
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { API, Auth, Storage } from "aws-amplify";
import AudioPlayer from "../components/AudioPlayer";
import { getCurrentFormattedTime } from "../utilities";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createRecord, updateRecord } from "../utilities/request";

interface FinalFormProps {
  finalData?: any;
  student: string;
  type: string;
  typeID: string;
  setOpen: (value: boolean) => void;
  backToPractice?: () => void;
}

const FinalForm: React.FC<FinalFormProps> = ({
  finalData,
  student,
  type,
  typeID,
  setOpen,
  backToPractice,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const createRecordMutation = useMutation({
    mutationFn: (input: any) => createRecord(input),
    onSuccess: (data: any) => {
      console.log("successfully created Final Version", data);
      queryClient.invalidateQueries({
        queryKey: ["speakingRecord", student, type, typeID],
      });
      enqueueSnackbar("Final version was created successfully", {
        variant: "success",
      });
      onCancel();
    },
    onError: (error: any) => {
      console.log("Final creation error", error);
      enqueueSnackbar(
        "Error while creating Final version, please try again later",
        {
          variant: "error",
        }
      );
    },
  });
  const updateRecordMutation = useMutation({
    mutationFn: (input: any) => updateRecord(input),
    onSuccess: (data: any) => {
      console.log("successfully updated Final Version", data);
      queryClient.invalidateQueries({
        queryKey: ["speakingRecord", student, type, typeID],
      });
      enqueueSnackbar("Final version was updated successfully", {
        variant: "success",
      });
      onCancel();
    },
    onError: (error: any) => {
      console.log("Final update error", error);
      enqueueSnackbar(
        "Error while updating Final version, please try again later",
        {
          variant: "error",
        }
      );
    },
  });
  const transcribeMutation = useMutation({
    mutationFn: async (text: string) =>
      API.post("aws", "/polly", {
        body: { text, voiceId },
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }),
    onSuccess: (data: any) => {
      setAudioUrl(data.audioURL);
    },
    onError: (error: any) => {
      console.log("transcribing error", error);
      enqueueSnackbar("Error while transcribing, please try again later", {
        variant: "error",
      });
    },
  });
  const isEdit = !!finalData;
  const initialData = isEdit
    ? { id: finalData.id, audio: finalData.audio, text: finalData.text }
    : {
        student,
        type,
        typeID,
        final: true,
        audio: "",
        text: "",
      };
  const [state, setState] = useState<any>(initialData);
  const [saving, setSaving] = useState(false);
  const [voiceId, setVoiceId] = useState("Ruth");
  const [audioUrl, setAudioUrl] = useState("");

  const handleVoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVoiceId((event.target as HTMLInputElement).value);
  };
  useEffect(() => {
    if (isEdit) {
      const fetchAudioUrl = async () => {
        try {
          const signedURL = await Storage.get(finalData.audio, {
            // @ts-ignore
            useAccelerateEndpoint: true,
          });
          setAudioUrl(signedURL);
        } catch (error) {
          console.log("fetch signedURL error", error);
        }
      };
      fetchAudioUrl();
    }
  }, [finalData]);
  const toAudio = async () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(state.text, "text/html");
    const paragraphs = Array.from(doc.querySelectorAll("p"));
    const text = paragraphs.map((p) => p.textContent).join(" ");
    transcribeMutation.mutate(text);
  };
  const onSubmit = async () => {
    let audioKey;
    setSaving(true);
    try {
      if (!audioUrl.startsWith("http://boxwhy-speaking-amplify90747-dev.s3")) {
        const response = await fetch(audioUrl);
        const blob = await response.blob();
        const result = await Storage.put(
          `${student}/final/${getCurrentFormattedTime()}.mp3`,
          blob,
          {
            contentType: blob.type,
            useAccelerateEndpoint: true,
          }
        );
        audioKey = result.key;
      } else {
        audioKey = state.audio;
      }
      const input = { ...state, audio: audioKey };
      setState(input);
      if (!isEdit) {
        //create
        createRecordMutation.mutate(input);
      } else {
        //update
        updateRecordMutation.mutate(input);
      }
      setSaving(false);
      onCancel();
    } catch (error) {
      console.log("error on Saving", error);
      setSaving(false);
    }
  };
  const onCancel = () => {
    setAudioUrl("");
    setSaving(false);
    if (!isEdit && backToPractice) {
      setState(initialData);
      backToPractice();
    } else {
      setOpen(false);
    }
  };
  const isValidatedText = state.text.trim().split(/\s+/).length > 10;
  const isValidated = isValidatedText && audioUrl && state.text;
  return (
    <Box>
      <CKEditor
        editor={Editor}
        data={state.text}
        onChange={(_event, editor) => {
          const data = editor.getData();
          setState({ ...state, text: data });
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          my: 2,
          alignItems: "center",
        }}
      >
        <FormControl>
          <FormLabel>Choose Voice</FormLabel>
          <RadioGroup row value={voiceId} onChange={handleVoiceChange}>
            <FormControlLabel value="Ruth" control={<Radio />} label="Female" />
            <FormControlLabel
              value="Stephen"
              control={<Radio />}
              label="Male"
            />
          </RadioGroup>
        </FormControl>
        <Button
          onClick={toAudio}
          disabled={!isValidatedText || transcribeMutation.isPending}
        >
          {transcribeMutation.isPending ? "Transcribing" : "Transcribe"}
        </Button>
      </Box>
      {audioUrl && <AudioPlayer src={audioUrl} />}

      <Box sx={{ mt: 3, display: "flex", flexDirection: "row-reverse" }}>
        <Button
          variant="outlined"
          disabled={!isValidated || saving}
          onClick={onSubmit}
        >
          {saving ? "Saving..." : "Submit"}
        </Button>
        <Button variant="outlined" onClick={onCancel} sx={{ mr: 1 }}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default FinalForm;
